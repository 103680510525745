/* styles.css */
.gallery-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .gallery-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
  
  .photo-item {
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .photo-item:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .photo-image {
    border: 2px solid #fff;
    transition: border-color 0.3s ease;
  }
  
  .photo-image:hover {
    border-color: #009fe3;
  }
  